// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@import './sass/theme/ng-select/primeng.theme';
@import './sass/render-error';
@import './sass/mdc-migration';
@import './sass/to-be-removed';
@import './sass/variables';
@import './sass/fake-grid';
@import './sass/colours';
@import 'isla-colours';

@include mat.core();

$boris-primary: mat.define-palette($md-nhswarm);
$boris-accent: mat.define-palette($md-nhswarm, 500);
$boris-warn: mat.define-palette($md-nhswarm, 500);

$boris-theme: mat.define-light-theme(
  (
    color: (
      primary: $boris-primary,
      accent: $boris-accent,
      warn: $boris-warn,
    ),
  )
);

@include mat.all-component-themes($boris-theme);

@font-face {
  font-family: 'Material Symbols';
  font-style: normal;
  src: url('./sass/theme/fonts/Material-Symbols.woff2') format('woff');
}

@import './assets/tailwind/base.scss';
@import './assets/tailwind/components.scss';
@import './assets/tailwind/utilities.scss';

$header-height: 56px;
// Mobile breakpoint variable
$mobile-breakpoint: 767px;

.p-button-nav span {
  font-size: 0.875rem !important;
  line-height: unset !important;
}

html,
body {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background: $bg-main;
}

main {
  -webkit-overflow-scrolling: touch;
}

*:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $el-bg-primary-light;
}

// To be used by pages that want to be full height (g.g, pages that have sidebars)
.h-screen-minus-header {
  min-height: calc(100vh - $header-height);
}

.main-router-outlet + * {
  @apply min-h-full;
}

// https://stackoverflow.com/a/16255670 - Fixes iOS zoom (AS-34)
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

.pathway-template-dialog {
  &.p-dynamic-dialog {
    margin: 0 !important;
  }
}

ic-app-form {
  .app-formly-inline-wrapper {
    & > formly-group {
      display: flex;
    }
  }

  p-inputNumber {
    input {
      width: 100%;
    }
  }
}

.p-dialog {
  // This is temporary and can be removed onced theme is unfrozen
  max-height: 95% !important;

  &.dialog-no-padding {
    .p-dialog-content {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
}

// This can be removed once tailwind is in use
.cursor-not-allowed {
  cursor: not-allowed;
}

// Some PrimeFlex overrides to be properly defined in the
// theme once the theme is pulled in from theme-designer
.field {
  &:not(ic-patient-view-filters *) {
    label {
      margin-bottom: 0.25rem !important;
    }
  }
}

.p-multiselect-panel {
  max-width: 85vw !important;
}

.p-inline-message .p-inline-message-text span {
  font-size: 0.875rem !important;
}

.p-checkbox-label {
  font-weight: normal !important;
}

.p-button.p-button-hide-icon-mobile {
  @media (max-width: $mobile-breakpoint) {
    .p-button-icon {
      display: none;
    }
  }
}

.p-datatable .p-datatable-tbody > tr {
  &:hover {
    background-color: $el-bg-primary-subtle-alt !important;
  }
}

.p-datatable {
  &:has(p-paginator) {
    border-bottom: 1px solid #a7acaf;
  }
}

.p-datatable-wrapper {
  border-radius: 6px 6px 0px 0px;
}

// Temporary home, to be removed by Tailwind config once PrimeFlex removed as there's no way to make specific areas of the config a priority over PrimeFlex
.nhs-blue {
  background: #005eb8 !important;
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.75rem 0 !important;
}

.patient-record-folder-list .p-tree .p-tree-container .p-treenode {
  overflow: hidden !important;
}

.patient-tag-selection {
  .p-tree {
    background: white !important;
    .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-checkbox-disabled {
      margin-right: 0.5rem;
      display: none !important;
    }
  }
}

.filter-active.p-multiSelect {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--focus-ring);
  border-color: var(--focus-border);
  border-radius: 6px;
}

.ers-entry-card-pdf-viewer {
  .ng2-pdf-viewer-container {
    overflow-x: hidden !important;
  }
}

.epr-icon {
  width: 1.625rem !important;
  height: 1.625rem !important;
}

.p-button span .p-multiselect-label,
.p-multiselect-token-label,
.p-placeholder,
.p-inputtext,
.p-button,
.p-chips input {
  line-height: 1.25rem !important;
  font-size: 0.875rem !important;
}

// App-wide button padding fix
@media (min-width: 960px) {
  button .p-button.p-button-icon-only:not(.p-button-rounded, .p-dialog-header-icon) .p-button span .p-multiselect-label,
  .p-multiselect .p-multiselect-label,
  .p-placeholder,
  .p-inputtext,
  .p-button,
  .p-chips input {
    padding: 0.5rem 0.75rem !important;
  }

  .p-button-nav button.p-button:not(button.p-button-icon-only),
  .p-button.p-button-icon-only:not(.p-button-rounded, .p-dialog-header-icon) {
    padding: 0.5rem !important;
  }

  .p-button.p-button-icon-only:not(
      .p-button.p-button-icon-only.toggle-details-button,
      .card-menu-button,
      .p-button-xs
    ) {
    width: 2.5rem !important;
  }
  .p-button-xs.p-button-icon-only {
    width: 2rem !important;
  }
  .mark-as-reviewed-toggle:not(.p-dialog-footer .mark-as-reviewed-toggle) {
    padding: 0.75rem !important;
  }
  .iti input {
    height: 38px !important;
    line-height: 1.25rem !important;
    font-size: 0.875rem !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.75rem !important;
  }

  .p-tabview-panel {
    background: $bg-main-surface;
  }

  .p-dialog .p-dialog-footer button {
    margin: 0 0 0 0;
    width: auto;
  }

  .p-multiselect-label:has(.p-multiselect-token) {
    padding: 0.25rem !important;
    .p-multiselect-token-label {
      line-height: 1rem !important;
    }
  }
}

p-dialog {
  .p-dialog {
    &.full-screen-dialog {
      width: 100vw !important;
      height: 100vh !important;
      max-width: 100vw !important;
      max-height: 100vh !important;
    }
  }
}

// VG player
vg-player {
  vg-controls {
    z-index: 9 !important;
    * {
      color: white !important;
    }
  }
}

.p-input-icon-left .pi + .p-inputtext {
  padding-left: 2rem !important;
}

.p-dropdown-label {
  align-items: center;
  display: flex;
}

.isla-bg {
  background: #f3b625;
  background-image: radial-gradient(100% 62.5% at 50% 100%, #f7fafc 0%, rgba(177, 56, 94, 0) 100%),
    linear-gradient(
      147deg,
      #e96025 0%,
      #e96426 2.08%,
      #e96828 4.17%,
      #e96c29 6.25%,
      #e96f2b 8.33%,
      #ea732c 10.42%,
      #ea762d 12.5%,
      #ea792f 14.58%,
      #eb7d30 16.67%,
      #eb8032 18.75%,
      #ec8333 20.83%,
      #ed8936 25%,
      #ec8f38 28.75%,
      #ec9539 32.5%,
      #ec9b3b 36.25%,
      #eca03d 40%,
      #eca53e 43.75%,
      #ecab40 47.5%,
      #ecb042 51.25%,
      #ecb544 55%,
      #ecba46 58.75%,
      #ecbf47 62.5%,
      #ecc94b 70%,
      #edc748 71.33%,
      #edc645 72.67%,
      #eec442 74%,
      #eec33f 75.33%,
      #efc13c 76.67%,
      #efbf39 78%,
      #f0be36 79.33%,
      #f0bc32 80.67%,
      #f1ba2e 82%,
      #f2b82a 83.33%,
      #f3b521 86%
    );
}

.isla-wing-container {
  img {
    position: absolute;
  }

  .isla-wing-left,
  .isla-wing-right {
    height: 100%;
  }

  /* Default widths (larger screens) */
  .isla-wing-left {
    bottom: -15%;
    width: 80%;
    left: 0;
  }

  .isla-wing-right {
    width: 20%;
    right: 0;
    top: 0;
  }
}

// This is for the telephone-input component
ic-app-form-telephone-input {
  .iti {
    margin: 0 !important;
  }

  .iti input {
    border: 1px solid #d3d6d7 !important;
    padding-left: 3rem !important;
  }

  .iti__country-list {
    min-width: 300px !important;
  }
}

ic-app-form-chips {
  .p-chips-multiple-container {
    padding: 0 0.25rem !important;
  }

  .p-chips-input-token {
    padding: 0 0.25rem !important;

    input {
      padding-left: 0.25rem !important;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

ic-add-patient-form-dialog {
  // Unsure if we want to enable this CSS globally just yet but it
  // nicely fixes app formly groups so they're spaced better

  .app-form {
    height: unset !important;

    .app-form-field-wrapper,
    .app-form-group-wrapper {
      margin-bottom: 0rem !important;
    }
  }

  formly-group {
    display: flex;
    flex-direction: column;

    gap: 1rem;
  }

  .p-message svg.p-icon {
    height: 1rem !important;
    width: 1rem !important;
  }
  .p-message .p-message-wrapper {
    padding: 0.5rem !important;
  }
}

// Soon to be global messages, just these two for now
ic-add-patient-form-dialog,
ers-dashboard {
  .p-message svg.p-icon {
    height: 1rem !important;
    width: 1rem !important;
  }
  .p-message .p-message-wrapper {
    padding: 0.5rem !important;
  }
}

ers-dashboard {
  .p-message {
    margin: 0rem !important;
  }
}

.p-dropdown-clearable {
  timesicon {
    // For some reaosn, the clear icon doesn't have the correct spacing, this corrects that.
    // To be combined with the theme once it's moved over from being an external repo.
    padding: 0 0.5rem 0 0.5rem !important;
  }
}

p-scroller {
  .p-scroller-content {
    width: 100% !important;
  }
}

@media (min-width: $mobile-breakpoint) {
  .p-dropdown-items-wrapper {
    max-height: 40vh !important;
  }
}

.small-badge {
  .p-badge {
    font-size: 0.6rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    padding: 0 0.25rem;
    word-break: normal;
  }
}

.page-spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

ic-homepage {
  .p-tabview-panels {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .p-tabview-panel {
      background: $bg-main !important;
    }
  }
}

.p-divider {
  background: inherit;

  .p-divider-content {
    &:has(span) {
      background: inherit;

      span {
        background: inherit;
      }
    }
  }
}

.p-breadcrumb {
  @apply p-0 bg-transparent border-0;

  ol li {
    .p-menuitem-link .p-menuitem-text {
      @apply text-lg/5 text-textSecondary;
    }

    &.p-menuitem-separator {
      @apply mx-2 my-0 text-textSecondary;
    }

    &:last-child .p-menuitem-text {
      @apply font-semibold text-textMain;
    }
  }
}

// Add viewport fix for iOS virtual keyboard
@media screen and (max-width: $mobile-breakpoint) {
  html {
    height: 100%;
  }

  body {
    min-height: 100%;
    position: relative;
  }
}

// Expanded touch target for clickable elements. Add to a parent element to apply hover styles when parent is hovered.
.expanded-touch-target {
  &:hover {
    // Checkbox hover
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
      border-color: $border-brand-subtle;
    }
  }
}
